// モジュールとなるjsファイルの読み込み
// analytics
import {module_analytics} from './js/googleAnalytics';

// vendors
import {module_jquery_easing} from './js/jquery.easing';
import {module_infinitescroll} from './js/jquery.infinitescroll.min.js';
import {module_slick} from './js/slick.min';
import {module_lazysizes} from './js/lazysizes.min.js';
//import {module_validationEngine} from './js/jquery.validationEngine.min';
//import {module_validationEngineJa} from './js/jquery.validationEngine-ja';
//import {module_hashchange} from './js/jquery.ba-hashchange.min';
//import {module_autoConfirm} from './js/autoConfirm';
import {module_magnific} from './js/jquery.magnific-popup.min';
//import {module_scroll} from './js/scrollAnimation';
import {module_webfonts} from './js/webfonts';
import {module_tabs} from './js/tabs';

// pages
import {module_common} from './js/common';
import {module_index} from './js/index';


// モジュール先にある関数を実行
module_analytics();

module_jquery_easing();
module_infinitescroll();
module_slick();
module_lazysizes();
//module_validationEngine();
//module_validationEngineJa();
//module_hashchange();
//module_autoConfirm();
module_magnific();
//module_scroll();
module_webfonts();
module_tabs();

module_common();
module_index();

// Sassファイルの読み込み
//import './sass/style.scss';