export function module_tabs() {
	$(window).on('load', function () {

		$(".tabList").each(function() {
			$(this).children().first().find('a').addClass('is__active');
		});

		$(".tabContainer").each(function() {
			$(this).children().first().addClass('is__show');
		});

		//タブ切り替え
		$(".tabList__item a").on('click', function(e) {
			e.preventDefault();
			var target = $(this).attr('href');
			if (! $(target).length) return false;
			//タブのリンクのクラス削除
			$('.tabList__item a', $(this).closest('.tabList')).removeClass('is__active');
			//タブのリンクにクラス付与
			$(this).closest('.tabList__item a').addClass('is__active');
			//コンテンツ領域のクラス削除
			$('.tabContent', $(target).closest('.tabContainer')).removeClass('is__show');
			//コンテンツ領域にクラス付与
			$(target).addClass('is__show');
		});
	});
}
