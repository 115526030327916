export function module_index() {
	$(function() {
		$(window).on('load', function () {

			$('.loading').animate({opacity: 'hide'}, { duration: 1500, easing: 'easeInQuart' })
			
			//--------------------------------------------------------
			// slick setting
			//--------------------------------------------------------
			var slick = $('.slider').slick({
				dots:true,
				appendArrows: $('.arrows'),
				autoplay: true,
				autoplaySpeed: 4000/*,
				responsive: [{
					breakpoint: 1280,
					settings: {
						variableWidth: false
					}
				},{
					breakpoint: 480,
					settings: {
						centerPadding:'30px',
						variableWidth: false
					}
				}]*/
			});

			$('.slick-next').on('click', function(){
				$('.slider').slick('slickNext');
			});
			$('.slick-prev').on('click', function(){
				$('.slider').slick('slickPrev');
			});
		});
		
		
		//--------------------------------------------------------
		// Resize Event
		//--------------------------------------------------------
		var timer = false;
		var count = 0;

		$(window).on('load resize', function () {
			//リサイズ操作が終わった時だけ実行
			if (timer !== false) clearTimeout(timer);
			timer = setTimeout(commonResize, 200);
		});

		//共通リサイズイベント
		function commonResize() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;
			
			if($(window).width() > 787) {
				// slick 内の img を背景画像に
				$('.slick-slide').each(function(){
					var slideImg = $(this).find('img').attr('src');
					$(this).css('background-image', 'url(' + slideImg + ')');
					$(this).find('img').addClass('slick-img-none');
				});
			} else {
				$('.slick-slide').css('background-image', 'none');
				$('.slick-slide').find('img').removeClass();
			}
		} //commonResize
	});
}