export function module_common() {
	$(function() {
		//--------------------------------------------------------
		// メニュー展開後背景コンテンツを固定
		//--------------------------------------------------------
		var state = false;
		var scrollpos;

		$('.btnSpMenu, .spMenuOverflow').on('click', function(event){
			event.preventDefault();
			
			if(state == false) {
				// 背景コンテンツ固定
				scrollpos = $(window).scrollTop();
				$('body').addClass('fixed').css({'top': -scrollpos});
				// グローバルメニュー表示
				$('.gNavMenu').fadeIn(200);
				$('.gNavMenuBox').addClass('openMenu');
				state = true;
			} else {
				// 背景コンテンツ固定 解除
				$('body').removeClass('fixed').css({'top': 0});
				window.scrollTo( 0 , scrollpos );
				// グローバルメニュー非表示
				$('.gNavMenu').fadeOut(200);
				$('.gNavMenuBox').removeClass('openMenu');
				state = false;
			}
		});

		//--------------------------------------------------------
		// Resize Event
		//--------------------------------------------------------
		commonResize();
		
		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on("resize", function() {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function() {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if ( winWidth != winWidth_resized || winHeight != winHeight_resized ) {
					commonResize();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//共通リサイズイベント
		function commonResize() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;
			
			var headerHeight = $('.hContainer').outerHeight();
			$('.contents').css('paddingTop', headerHeight);
			
			//windowサイズが788px以上になったとき、サイドナビを閉じる
			if($(window).width() >= 1000) {
				$('.gNavMenu').css("display","flex");
				$('.gNavMenu').insertAfter('.btnSpMenu');
			} else {
				$('.gNavMenu').css("display","none");
				$('.gNavMenu').insertBefore('header');
			}
		} //commonResize

		//--------------------------------------------------------
		// アンカーリンク
		//--------------------------------------------------------
		$('a[href*="#"]:not(.external-link)').click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
			&& location.hostname == this.hostname) {
				var $target = $(this.hash);
				$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
				if ($target.length) {
					var targetOffset = $target.offset().top - 90;
					$('body,html').animate({scrollTop: targetOffset}, 1500,'easeInOutQuart');
					return false;
				}
			}
		});

		//--------------------------------------------------------
		// PAGETOP
		//--------------------------------------------------------
		var topBtn = $('.pagetop');	
		topBtn.hide();
		$(window).scroll(function () {
			if ($(this).scrollTop() > 160) {
				topBtn.fadeIn();
			} else {
				topBtn.fadeOut();
			}
		});
		
		//スクロールしてトップ
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 1500,'easeInOutQuart');
			return false;
		});
	});
}